import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import HeaderSection from '../components/section-header'
import FeatureListSection from '../components/section-feature-list'
import TextSection from '../components/section-text'

export default ({ data }) => {
  const { frontmatter } = data.markdownRemark
  
  return (
    <Layout>
      <HeaderSection {...frontmatter.header} />
      <TextSection text={frontmatter.intro} maxWidth="md" variant="h3" align="center" />
      <FeatureListSection features={frontmatter.features} />
    </Layout>
  )
}

export const query = graphql`
  query ServicesPageQuery($id: String!) {
    markdownRemark(id: {eq: $id }) {
      frontmatter {
        header {
          title
          backgroundColor
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        intro
        features {
          image {
            childImageSharp {
              fixed(width: 45, fit: CONTAIN) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          title
          eyebrow
          items
        }
        title
      }
      html
    }
  }
`